import store from 'src/store'
import { inIframe, sendIframeMessageWithResponse } from 'src/utils/iframe'
import { getQueryValue } from 'src/utils/query'

export const addGarmentsToWishlist = (params: any[], callback: (success: boolean) => void) => {
    // ---- Debug ----
    for (const garment of params) {
        console.log(garment.garment_id)
    }

    // ---- Mode demo ----
    const origin = getQueryValue('origin')
    if (!origin || origin.match(/^https?:\/\/(generateapp\.localhost|app\.veesual\.ai)\//)) {
        callback(true)
        return
    }

    // ---- Eileen Fisher ----
    if (inIframe() && ['eileenfisher.com'].indexOf(getQueryValue('domain')) !== -1) {
        // ---- Recuperation du base url ----
        sendIframeMessageWithResponse(
            'veesual_execute',
            [
                {
                    type: 'javascript',
                    payload: `(function(){ const match = document.body.innerHTML.match(/\\/on\\/demandware\\.store\\/Sites-ef-Site\\/[^/]+\\//); return match ? match[0] : null })()`,
                },
            ],
            (executeResponses) => {
                // ---- Gestion d'erreur ----
                if (
                    executeResponses === false ||
                    executeResponses.length !== 1 ||
                    !executeResponses[0]
                ) {
                    return callback(false)
                }

                // ---- Initialisation ----
                const payloads = []

                // ---- Pour chaque param ----
                for (const garment of params) {
                    // ---- Url en fonction de l'origin ----
                    const postUrl = executeResponses[0] + 'Wishlist-AddProduct'

                    // ---- Ajout au payload ----
                    payloads.push({
                        type: 'jquery_ajax',
                        payload: {
                            url: postUrl,
                            method: 'POST',
                            dataType: 'json',
                            data: {
                                pid: garment.product_external_id,
                                optionId: '',
                                optionVal: '',
                            },
                        },
                    })
                }

                // ---- Using jquery to handle the basic auth in dev ----
                sendIframeMessageWithResponse(
                    'veesual_execute',
                    payloads,
                    function (responses) {
                        if (responses === false) {
                            return callback(false)
                        }
                        const parsedResponse = responses.pop()
                        if (!parsedResponse || !parsedResponse.success) {
                            if (parsedResponse && parsedResponse.msg) {
                                if (
                                    parsedResponse.msg ==
                                    'The product is already in the wishlist and will not be added again.'
                                ) {
                                    return callback(true)
                                }
                            }
                            return callback(false)
                        }

                        return callback(true)
                    },
                    params.length * 2000
                )
            },
            2000
        )
    }
    // ---- Default ----
    else {
        callback(true)
    }
}

export const addGarmentToWishlist = (
    garment: Models.Garment,
    callback: (success: boolean) => void
) => {
    addGarmentsToWishlist([garment], callback)
}

export const removeGarmentsFromWishlist = (params: any[], callback: (success: boolean) => void) => {
    // ---- Debug ----
    for (const garment of params) {
        console.log(garment.garment_id)
    }

    // ---- Mode demo ----
    const origin = getQueryValue('origin')
    if (!origin || origin.match(/^https?:\/\/(generateapp\.localhost|app\.veesual\.ai)\//)) {
        callback(true)
        return
    }

    // ---- Eileen Fisher ----
    if (inIframe() && ['eileenfisher.com'].indexOf(getQueryValue('domain')) !== -1) {
        // ---- Recuperation du state ----
        const state = store.getState()

        // ---- On ne retire que quand une seule occurence en favoris ----
        const filteredParams = []
        for (const garment of params) {
            if (
                state.favorites.garments.filter(
                    (item) => item.product_external_id == garment.product_external_id
                ).length == 1
            ) {
                filteredParams.push(garment)
            }
        }

        // ---- Si c'est vide, on s'arrete la ----
        if (!filteredParams.length) {
            return callback(true)
        }

        // ---- Recuperation du base url ----
        sendIframeMessageWithResponse(
            'veesual_execute',
            [
                {
                    type: 'javascript',
                    payload: `(function(){ const match = document.body.innerHTML.match(/\\/on\\/demandware\\.store\\/Sites-ef-Site\\/[^/]+\\//); return match ? match[0] : null })()`,
                },
            ],
            (executeResponses) => {
                // ---- Gestion d'erreur ----
                if (
                    executeResponses === false ||
                    executeResponses.length !== 1 ||
                    !executeResponses[0]
                ) {
                    return callback(false)
                }

                // ---- Initialisation ----
                const payloads = []

                // ---- Pour chaque param ----
                for (const garment of params) {
                    // ---- Url en fonction de l'origin ----
                    const postUrl =
                        executeResponses[0] +
                        'Wishlist-RemoveProduct?pid=' +
                        encodeURIComponent(garment.product_external_id)

                    // ---- Ajout au payload ----
                    payloads.push({
                        type: 'jquery_ajax',
                        payload: {
                            url: postUrl,
                            method: 'GET',
                            dataType: 'json',
                        },
                    })
                }

                // ---- Using jquery to handle the basic auth in dev ----
                sendIframeMessageWithResponse(
                    'veesual_execute',
                    payloads,
                    function (responses) {
                        if (responses === false) {
                            return callback(false)
                        }
                        const parsedResponse = responses.pop()
                        if (!parsedResponse || !parsedResponse.success) {
                            return callback(false)
                        }

                        return callback(true)
                    },
                    params.length * 2000
                )
            },
            2000
        )
    }
    // ---- Default ----
    else {
        callback(true)
    }
}

export const removeGarmentFromWishlist = (
    garment: Models.Garment,
    callback: (success: boolean) => void
) => {
    removeGarmentsFromWishlist([garment], callback)
}

export const getGarmentsFromWishlist = (callback: (success: boolean, params?: any) => void) => {
    // ---- Mode demo ----
    const origin = getQueryValue('origin')
    if (!origin || origin.match(/^https?:\/\/(generateapp\.localhost|app\.veesual\.ai)\//)) {
        callback(true)
        return
    }

    // ---- Eileen Fisher ----
    if (inIframe() && ['eileenfisher.com'].indexOf(getQueryValue('domain')) !== -1) {
        // ---- Recuperation du base url ----
        sendIframeMessageWithResponse(
            'veesual_execute',
            [
                {
                    type: 'javascript',
                    payload: `(function(){ const match = document.body.innerHTML.match(/\\/on\\/demandware\\.store\\/Sites-ef-Site\\/[^/]+\\//); return match ? match[0] : null })()`,
                },
            ],
            (executeResponses) => {
                // ---- Gestion d'erreur ----
                if (
                    executeResponses === false ||
                    executeResponses.length !== 1 ||
                    !executeResponses[0]
                ) {
                    return callback(false)
                }

                // ---- Url en fonction de l'origin ----
                const getUrl = executeResponses[0] + 'Wishlist-FetchWishlistItem'

                // ---- Ajout au payload ----
                const payloads = [
                    {
                        type: 'jquery_ajax',
                        payload: {
                            url: getUrl,
                            method: 'GET',
                            dataType: 'json',
                        },
                    },
                ]

                // ---- Using jquery to handle the basic auth in dev ----
                sendIframeMessageWithResponse(
                    'veesual_execute',
                    payloads,
                    function (responses) {
                        if (responses === false) {
                            return callback(false)
                        }
                        const parsedResponse = responses.pop()
                        if (!parsedResponse || !parsedResponse.success) {
                            return callback(false)
                        }

                        if (parsedResponse.pids && parsedResponse.pids.length) {
                            return callback(true, {
                                filter: {
                                    raw_item_group_id: parsedResponse.pids,
                                },
                            })
                        }

                        return callback(true)
                    },
                    2000
                )
            },
            2000
        )
    }
    // ---- Default ----
    else {
        callback(true)
    }
}

export const getFilteredGarmentsForWishlist = (items: Models.Garment[]) => {
    // ---- Eileen Fisher ----
    if (['eileenfisher.com'].indexOf(getQueryValue('domain')) !== -1) {
        // ---- Recuperation du state ----
        const state = store.getState()

        // ---- Initialisations ----
        const filtered = []
        const alreadyUsed = []

        // ---- Recuperation de ceux qui sont dejs en favoris ----
        for (const favorite of state.favorites.garments) {
            for (const item of items) {
                // ---- Est-ce qu'on a deja ce garment en favoris ----
                if (favorite.garment_id == item.garment_id) {
                    filtered.push(item)
                    alreadyUsed.push(item.product_external_id)
                }
            }
        }

        // ---- Traitement du reste ----
        for (const item of items) {
            // ---- On l'avait deja dans les favoris ----
            if (alreadyUsed.includes(item.product_external_id)) {
                continue
            }

            // ---- On ajoute seulement une version ----
            filtered.push(item)
            alreadyUsed.push(item.product_external_id)
        }

        return filtered
    }

    return items
}
