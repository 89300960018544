import React from 'react'
import { useAppSelector } from 'src/store'
import { trackEvent } from 'src/utils/tracking'
import useCustomTranslation from 'src/utils/translation'

interface PoweredByProps {
    alwaysShow?: boolean
    overrideClassName?: string
    onClick?(): void
}

export default function PoweredBy(props: PoweredByProps) {
    const { t } = useCustomTranslation()
    const company = useAppSelector((state) => state.profile?.company)
    const clientInternal = company?.internal
    const { alwaysShow, overrideClassName, onClick } = props

    const handlePoweredClick = () => {
        if (onClick) {
            onClick()
            return
        }

        trackEvent('Powered Clicked', {}, 'Bottom')
    }

    const utms = `utm_source=client_experience&utm_medium=powered_by&utm_campaign=${clientInternal}`

    return (
        <div
            className={`override_poweredby ${!alwaysShow ? 'layout--poweredby-desktop' : ''} ${
                overrideClassName ? overrideClassName : ''
            }`}
        >
            {t('look.powered_by')}{' '}
            {company.enable_poweredby_link === undefined ||
            company.enable_poweredby_link === true ? (
                <a
                    href={`https://www.veesual.ai?${utms}`}
                    onClick={handlePoweredClick}
                    target='_blank'
                    rel='noreferrer'
                >
                    Veesual
                </a>
            ) : (
                'Veesual'
            )}
            {t('look.powered_by_after')}
        </div>
    )
}
