import { useDispatch } from 'react-redux'
import store, { useAppSelector } from 'src/store'
import { inIframe, sendIframeMessage } from './iframe'
import { trackEvent } from './tracking'
import { isScrollableY } from './scroll'
import { setHeaderHidden } from 'src/store/slices/profileSlice'

export default function useShowHideHeader() {
    const dispatch = useDispatch()
    const enableHideHeader = useAppSelector((state) => state.profile?.company?.enable_hide_header)

    function getVerticalyScrollableParent(node) {
        if (isScrollableY(node)) {
            return node
        }
        if (node.parentNode) {
            return getVerticalyScrollableParent(node.parentNode)
        }
        return null
    }

    let previousTouchX = null
    let previousTouchY = null
    function checkScrollDirectionIsUp(event) {
        if (event.type && event.type == 'touchstart') {
            previousTouchX = event.touches[0].clientX
            previousTouchY = event.touches[0].clientY
            throw 'Init previousTouch'
        }
        if (event.type && event.type == 'touchmove') {
            if (previousTouchY === null) {
                previousTouchX = event.touches[0].clientX
                previousTouchY = event.touches[0].clientY
                throw 'Init previousTouch'
            }
            const diffX = Math.abs(event.touches[0].clientX - previousTouchX)
            const diffY = Math.abs(event.touches[0].clientY - previousTouchY)
            if (diffX < 10 && diffY < 10) {
                throw 'Less than threshold'
            }
            const oldPreviousTouchY = previousTouchY
            previousTouchX = event.touches[0].clientX
            previousTouchY = event.touches[0].clientY
            if (diffX > diffY) {
                throw 'More horizontal than vertical'
            }
            return event.touches[0].clientY > oldPreviousTouchY
        }
        if (event.type && event.type == 'DOMMouseScroll') {
            return event.detail < 0
        }
        if (event.wheelDelta) {
            return event.wheelDelta > 0
        }
        return event.deltaY < 0
    }

    let canCallShowHideHeader = true
    function showHideHeader(show, targetName, msBetweenCalls = 500, category = 'Menu') {
        // ---- Can i call ----
        if (!canCallShowHideHeader) {
            return false
        }
        canCallShowHideHeader = false

        // ---- Show or hide ----
        if (show) {
            dispatch(setHeaderHidden(false))
            trackEvent('Parent header Show', { parent_header_from: targetName }, category)
            sendIframeMessage('veesual_header', { show: true })
        } else {
            dispatch(setHeaderHidden(true))
            trackEvent('Parent header Hide', { parent_header_from: targetName }, category)
            sendIframeMessage('veesual_header', { show: false })
        }

        // ---- Wait between calls ----
        setTimeout(() => {
            canCallShowHideHeader = true
        }, msBetweenCalls)
    }

    function showHideHeaderScrollListener(event) {
        if (!inIframe() || !enableHideHeader) {
            return
        }

        // ---- Recuperation de l'event ----
        const e = window.event || event

        // ---- Recuperation de la direction ----
        const headerHidden = store.getState().profile?.headerHidden
        const targetName = e.target === document ? `document` : e.target.getAttribute('id')

        try {
            const isDirectionUp = checkScrollDirectionIsUp(e)

            // ---- Recherche du premier parent scrollable ----
            const scrollableNode = getVerticalyScrollableParent(e.target)
            if (scrollableNode !== null) {
                if (scrollableNode.scrollTop == 0 && isDirectionUp) {
                    if (headerHidden) {
                        showHideHeader(true, targetName)
                    }
                } else if (!isDirectionUp) {
                    if (!headerHidden) {
                        showHideHeader(false, targetName)
                    }
                }
            } else if (isDirectionUp) {
                if (headerHidden) {
                    showHideHeader(true, targetName)
                }
            } else {
                if (!headerHidden) {
                    showHideHeader(false, targetName)
                }
            }
        } catch (exception) {
            // ---- Ignore ----
        }

        return true
    }

    return { showHideHeaderScrollListener, showHideHeader }
}
