import { Col, Row, Spin } from 'antd'
import React from 'react'
import { customSpinner as customEileenFisherSpinner } from 'src/static/eileen_spinner'
import { customSpinner as customLululemonSpinner } from 'src/static/lululemon_spinner'
import { getQueryValue } from 'src/utils/query'

export default function Loader(props: {
    size?: 'default' | 'small' | 'large'
    ignoreCustom?: boolean
}) {
    if (!props.ignoreCustom) {
        const domain = getQueryValue('domain')

        if (domain && domain.match(/^eileenfisher/)) {
            return customEileenFisherSpinner
        }

        if (domain && domain.match(/^lululemon/)) {
            return customLululemonSpinner
        }
    }

    return (
        <Row justify='space-around' align='middle' className='App--loading'>
            <Col>
                <Spin size={props.size || 'large'} />
            </Col>
        </Row>
    )
}
