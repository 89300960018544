import React, { useEffect, useState } from 'react'
import { trackEvent } from 'src/utils/tracking'
import useCustomTranslation from 'src/utils/translation'

interface Color {
    name: string
    colorUrl: string
}

interface ColorPickerProps {
    garment: Models.Garment
    colors: Color[]
    value?: Color
    onValueChange?(newValue: Color): void
    eventCategory?: string
}

export default function ColorPicker(props: ColorPickerProps) {
    const { t } = useCustomTranslation()

    const { garment, colors, value, onValueChange, eventCategory = 'Color Picker' } = props

    const [localValue, setLocalValue] = useState<Color>(value)

    useEffect(() => {
        if (value !== localValue) {
            setLocalValue(value)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    const handleColorChange = (newColor: Color) => {
        setLocalValue(newColor)
        if (onValueChange && newColor.colorUrl !== value.colorUrl) {
            trackEvent(
                'Item color Changed',
                [garment, { color_from: value.name, color_to: newColor.name }],
                eventCategory
            )
            onValueChange(newColor)
        }
    }

    return (
        <div className='colorpicker--container'>
            <div>
                {t('product.color')}: {localValue?.name}
            </div>
            <div className='colorpicker--color-container'>
                {colors.map((color) => (
                    <div key={color.colorUrl + color.name} style={{ position: 'relative' }}>
                        <div
                            onClick={() => handleColorChange(color)}
                            style={{
                                backgroundImage: `url(${color.colorUrl})`,
                            }}
                            className='colorpicker--color'
                        ></div>
                        {localValue?.colorUrl === color.colorUrl && (
                            <div className='colorpicker--color-outline' />
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}
