import store from 'src/store'
import { apiGarment } from 'src/store/api/api-garment'
import {
    addFilterForType,
    getAllGarments,
    getCurrentActiveFiltersForType,
    removeFilterForType,
} from 'src/store/slices/databaseSlice'

export const removeVariantFilter = () => {
    const state = store.getState()
    const dispatch = store.dispatch
    const garmentType = state.garment.type
    const allGarments = getAllGarments(state)
    const activeFilters = getCurrentActiveFiltersForType(state, garmentType)
    const activeVariantValues = activeFilters['garment_variant_ids']

    // ---- Check if we have and active Variant Filter ----
    if (activeVariantValues.length > 0) {
        // ---- Clone to remove the active filter and fetch with new active filters ----
        const activeFilterClone = JSON.parse(JSON.stringify(activeFilters))
        dispatch(
            removeFilterForType({
                type: garmentType,
                facetKey: 'garment_variant_ids',
                facetValue: activeVariantValues[0],
            })
        )

        // ---- Remove active variant filter ----
        delete activeFilterClone['garment_variant_ids']

        // ---- Fetch new garments ----
        dispatch(
            apiGarment.endpoints.getGarments.initiate({
                ...(allGarments[garmentType]?.filterGarmentId
                    ? { garment_id: allGarments[garmentType].filterGarmentId }
                    : null),
                type: garmentType,
                page: 1,
                filter: activeFilterClone,
            })
        )
    }
}

export const addVariantFilter = (id: string) => {
    const state = store.getState()
    const dispatch = store.dispatch
    const garmentType = state.garment.type
    const allGarments = getAllGarments(state)
    const activeFilters = getCurrentActiveFiltersForType(state, garmentType)
    const activeVariantValues = activeFilters['garment_variant_ids']

    if (!activeVariantValues || activeVariantValues.length === 0) {
        dispatch(
            apiGarment.endpoints.getGarments.initiate({
                ...(allGarments[garmentType]?.filterGarmentId
                    ? { garment_id: allGarments[garmentType].filterGarmentId }
                    : null),
                type: garmentType,
                page: 1,
                filter: { garment_variant_ids: [id] },
            })
        )

        dispatch(
            addFilterForType({
                type: garmentType,
                facetKey: 'garment_variant_ids',
                facetValue: id,
            })
        )
    }
}
