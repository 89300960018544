import { Badge, Button } from 'antd'
import React, { MouseEvent } from 'react'
import { getFilterIcon } from 'src/utils/icon'

interface FilterButtonProps {
    onClick?: (e: MouseEvent) => void
    countFilter: number
    classNameOverride?: string
    buttonClassNameOverride?: string
}

export default function FilterButton(props: FilterButtonProps) {
    const { onClick, countFilter, classNameOverride, buttonClassNameOverride } = props

    return (
        <div
            className={`${classNameOverride ? classNameOverride : 'button--filter'}`}
            onClick={(e) => onClick && onClick(e)}
        >
            <Badge count={countFilter} offset={[-5, 10]} color='white' size='small'>
                <Button
                    icon={getFilterIcon()}
                    type='text'
                    className={`button ${
                        buttonClassNameOverride
                            ? buttonClassNameOverride
                            : 'layout--header-filter-button'
                    }`}
                />
            </Badge>
        </div>
    )
}
