import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import useCustomTranslation from 'src/utils/translation'

import { CloseOutlined } from '@ant-design/icons'
import { useAppSelector } from 'src/store'
import {
    addFilterForType,
    getAllGarments,
    getCurrentActiveFiltersForType,
    removeFilterForType,
    resetAllFilterForType,
    resetAllGarmentsForType,
} from 'src/store/slices/databaseSlice'
import useCustomGetGarments from 'src/utils/custom-getGarments-hook'
import { FetchTypeGarmentAction } from 'src/store/actions/garment'

import { trackEvent } from 'src/utils/tracking'
import PoweredBy from 'src/components/PoweredBy'
import CollpaseCheckbox from 'src/components/collapse/CollapseCheckbox'
import useTypeFunctions from 'src/utils/typeMethods-hook'
import { Button, Col, Row, Tabs } from 'antd'
import Loader from 'src/components/Loader'
import CloseButton from 'src/components/button/CloseButton'
import { setShowFilter } from 'src/store/slices/layoutSlice'

interface FilterResponsiveProps {
    allFilters: { [key: string]: Models.Facet[] } | null
    isMobile?: boolean
    onFilterUpdate?(filterValue?: string | null, facetKey?: string): void
}

const FilterResponsive: React.FunctionComponent<FilterResponsiveProps> = (props) => {
    const dispatch = useDispatch()
    const { t } = useCustomTranslation()
    const { getTypesFromPrimaries } = useTypeFunctions()

    const allGarments = useAppSelector((state) => getAllGarments(state))
    const company = useAppSelector((state) => state.profile?.company)
    const currentGarmentType = useAppSelector((state) => state.garment.type)
    const activeFilters = useAppSelector((state) =>
        getCurrentActiveFiltersForType(state, currentGarmentType)
    )
    const look = useAppSelector((state) => state.look)

    const [getGarmentsTrigger, { isLoading }] = useCustomGetGarments()

    const menuArray = useMemo(() => {
        const tmpArray = []
        company.garment_types.forEach((localGarmentType) => {
            tmpArray.push({
                label: t(`layout.${localGarmentType.toLowerCase()}`),
                value: localGarmentType,
            })
        })

        return tmpArray
    }, [company.garment_types, t])

    const MenuOption = menuArray
        ? menuArray
        : [
              { label: t('layout.top'), value: 'TOP' },
              { label: t('layout.bottom'), value: 'BOTTOM' },
              { label: t('layout.dress'), value: 'DRESS' },
              { label: t('layout.outerwear'), value: 'OUTERWEAR' },
              { label: t('layout.other'), value: 'OTHER' },
          ]

    const { allFilters, isMobile, onFilterUpdate } = props

    const getGarmentWithNewFilter = (newFilters: { [key: string]: string[] }) => {
        getGarmentsTrigger({
            ...(allGarments[currentGarmentType]?.filterGarmentId
                ? { garment_id: allGarments[currentGarmentType].filterGarmentId }
                : null),
            type: currentGarmentType,
            page: 1,
            filter: newFilters,
        })
            .unwrap()
            .then((resp) => {
                // We check if there is any garment in the response if not we retry without unnecessary filter
                if (resp.items.length === 0) {
                    Object.keys(newFilters).forEach((filterKey) => {
                        newFilters[filterKey].forEach((newFilterValue) => {
                            // Searching the object corresponding to the value so we can get the count
                            const foundValueObject = allFilters[currentGarmentType][
                                filterKey
                            ].data.find((filterData) => filterData.value === newFilterValue)
                            if (foundValueObject.count === 0) {
                                delete newFilters[filterKey]
                            }
                        })
                    })
                    getGarmentsTrigger({
                        ...(allGarments[currentGarmentType]?.filterGarmentId
                            ? { garment_id: allGarments[currentGarmentType].filterGarmentId }
                            : null),
                        type: currentGarmentType,
                        page: 1,
                        filter: newFilters,
                    })
                }
            })
    }

    const onChange = (value: string, name?: string, e?: React.ChangeEvent<HTMLInputElement>) => {
        // When no value we fetch without filter and dispatch the reset action
        if (!value) {
            trackEvent('Filter Reset', { catalog_type: currentGarmentType }, 'Filter Garment')

            getGarmentWithNewFilter({})

            onFilterUpdate()

            return dispatch(resetAllFilterForType(currentGarmentType))
        }

        const action = e?.target?.checked
        trackEvent(
            'Filter Updated',
            {
                catalog_type: currentGarmentType,
                filter_name: name,
                filter_value: value,
                filter_action: action === undefined ? 'remove' : action ? 'check' : 'uncheck',
            },
            'Filter Garment'
        )

        // We search for our value in every ACTIVE filter keys
        const filteredOptions = Object.keys(activeFilters).filter((one) => {
            return activeFilters[one].findIndex((activeValue) => activeValue === value) !== -1
        })

        // Clone of object from the store to handle them
        const newActiveFilter = Object.assign({}, activeFilters)
        const valuesForKey = Object.assign([], newActiveFilter[name])

        // If we found our value we remove it from the active filters and fetch the new garments
        if (filteredOptions.length > 0) {
            valuesForKey.splice(
                valuesForKey.findIndex((localValue) => localValue === value),
                1
            )
            if (valuesForKey.length === 0) {
                delete newActiveFilter[filteredOptions[0]]
            } else {
                newActiveFilter[name] = valuesForKey
            }
            onFilterUpdate(null, name)
            getGarmentWithNewFilter(newActiveFilter)

            return dispatch(
                removeFilterForType({
                    type: currentGarmentType,
                    facetKey: filteredOptions[0],
                    facetValue: value,
                })
            )
        }

        // If we did not find our value we add it to our local object and the store
        if (newActiveFilter[name]) {
            valuesForKey.push(value)
            newActiveFilter[name] = valuesForKey
        } else {
            newActiveFilter[name] = [value]
        }
        getGarmentWithNewFilter(newActiveFilter)
        onFilterUpdate(value, name)

        return dispatch(
            addFilterForType({ type: currentGarmentType, facetKey: name, facetValue: value })
        )
    }

    const handleResetFilters = () => {
        trackEvent('Filter Reset All', {}, 'Filter Garment')

        const resetedType = []
        onFilterUpdate()
        Object.keys(allGarments).map((type) => {
            if (Object.keys(allGarments[type].activeFilters).length > 0) {
                resetedType.push(type)
                dispatch(resetAllGarmentsForType(type))
            }
        })

        getGarmentWithNewFilter({})

        // We need to check if we have the data for all the mandatoryTypes according to the current garmentType
        // If not we fetch it
        const mandatoryTypes = getTypesFromPrimaries(currentGarmentType)
        mandatoryTypes.forEach((mandatoryType: string) => {
            if (
                mandatoryType !== currentGarmentType &&
                resetedType.findIndex((resetType) => resetType === mandatoryType) !== -1
            ) {
                getGarmentsTrigger({
                    garment_id: look[mandatoryType.toLowerCase()]?.garment_id,
                    type: mandatoryType,
                    page: 1,
                    filter: {},
                })
            }
        })
    }

    const handleTypeClick = (value: string) => {
        trackEvent('Type Clicked', { catalog_type: value }, 'Filter Garment')
        dispatch(FetchTypeGarmentAction(value))
    }

    const closeModal = (from) => {
        trackEvent('Filter Close', { from: from }, 'Filter Garment')
        dispatch(setShowFilter(false))
    }

    return (
        <div className='filters--container' style={isMobile && { paddingBottom: 24 }}>
            <div>
                <Row className='filters--close-row'>
                    <CloseButton onClick={() => closeModal('cross')} />
                </Row>
                <Row className='filters--head'>
                    <Row style={{ flex: 1 }}>
                        <h2 className='filters--title'>{t('filter.title_product')}</h2>
                        <div style={{ width: 'auto' }}>{isLoading && <Loader size='small' />}</div>
                    </Row>
                    <Button
                        type='text'
                        onClick={handleResetFilters}
                        className='button--underlined'
                        size='small'
                    >
                        {t(`filter.reset`)}
                    </Button>
                </Row>
                {MenuOption && (
                    <Tabs
                        onTabClick={(key) => handleTypeClick(key)}
                        activeKey={currentGarmentType}
                        className='menu--filter'
                        items={MenuOption.map((item: { label: string; value: string }) => {
                            if (company && company?.garment_types?.indexOf(item.value) !== -1) {
                                let activeFilterLength = 0
                                if (allGarments[item.value]) {
                                    Object.keys(allGarments[item.value].activeFilters).forEach(
                                        (activeKey) => {
                                            activeFilterLength +=
                                                allGarments[item.value].activeFilters[activeKey]
                                                    .length
                                        }
                                    )
                                }

                                return {
                                    key: item.value,
                                    label: `${item.label} 
                                ${activeFilterLength > 0 ? `(${activeFilterLength})` : ''}`,
                                }
                            }
                        })}
                    />
                )}
                <>
                    {activeFilters && (
                        <Row
                            justify='space-between'
                            className='filters--filter-summary'
                            wrap={false}
                        >
                            <Col className='filters--tab-container'>
                                {Object.keys(activeFilters).map((filterKey) => {
                                    return activeFilters[filterKey].map((value) => {
                                        return (
                                            <div key={value} className='filters--tab'>
                                                <CloseOutlined
                                                    onClick={() => {
                                                        onChange(value, filterKey)
                                                    }}
                                                />
                                                <div>
                                                    {filterKey === 'garment_variant_ids'
                                                        ? t('filter.garment_variant_ids')
                                                        : value}
                                                </div>
                                            </div>
                                        )
                                    })
                                })}
                            </Col>
                            {Object.keys(activeFilters).length > 0 && (
                                <Col>
                                    <Button
                                        type='text'
                                        onClick={() => onChange(null)}
                                        className='button--underlined'
                                        size='small'
                                    >
                                        {t(`filter.clear_all`)}
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    )}
                </>
                <Row gutter={24} justify='center' className='filters filters--responsive'>
                    {allFilters &&
                        allFilters[currentGarmentType] &&
                        Object.keys(allFilters[currentGarmentType]).map((facet) => (
                            <Col
                                className={`gutter-row filter--field-${facet}`}
                                key={facet}
                                span={24}
                            >
                                <CollpaseCheckbox
                                    onChange={onChange}
                                    name={facet}
                                    placeholder={allFilters[currentGarmentType][facet].label}
                                    options={allFilters[currentGarmentType][facet].data}
                                    value={
                                        activeFilters &&
                                        activeFilters[facet] &&
                                        activeFilters[facet]
                                    }
                                    disable={isLoading}
                                />
                            </Col>
                        ))}
                </Row>
            </div>
            <div>
                <Row>
                    <Button
                        className='button card--main'
                        onClick={() => closeModal('apply')}
                        type='primary'
                        size='large'
                    >
                        {t('filter.apply')}
                    </Button>
                </Row>
                <PoweredBy />
            </div>
        </div>
    )
}

export default FilterResponsive
