import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useAppSelector } from 'src/store'
import { FetchTypeGarmentAction } from 'src/store/actions/garment'
import { HandleLookRequest } from 'src/store/actions/look'
import useTypeFunctions from './typeMethods-hook'

// Hook that give method to call when we click on a new type
export default function useTypeClick() {
    const location = useLocation()
    const dispatch = useDispatch()
    const { isPrimaryOrSecondaryType, getTypesFromPrimariesAndSecondaries } = useTypeFunctions()

    const lookRequest = useAppSelector((state) => state.look.request)
    const garmentsHistory = useAppSelector((state) => state.look.garmentsHistory)
    const currentType = useAppSelector((state) => state.garment.type)

    // Custom method when we clic on a type in the stylebar
    function styleBarTypeClick(type: string, iconClick?: boolean) {
        // ---- We don't handle the click when there is not the garmentsHistory value for this type ----
        if (!garmentsHistory[type]) {
            return
        }

        const cloneLookRequest = JSON.parse(JSON.stringify(lookRequest))
        const lowerType = type.toLowerCase()

        const isPrimaryTypeValue = isPrimaryOrSecondaryType(type)
        const isOptionalType = lookRequest.optional_types?.includes(type)

        // ---- If we have the same garment on the look already we only change the garment type ----
        if (cloneLookRequest[lowerType]?.garment_id === garmentsHistory[type].garment_id) {
            dispatch(FetchTypeGarmentAction(type))
            return
        }

        if (!iconClick) {
            dispatch(FetchTypeGarmentAction(type))
        }

        // Remove mode as we don't want it to override the value from the new look
        if (cloneLookRequest['mode']) {
            delete cloneLookRequest['mode']
        }

        // We remove the additionnal garment if it's already shown and we are already in this category
        // but we keep the other garments
        if (
            (!isPrimaryTypeValue || isOptionalType) &&
            (!lookRequest[lowerType] || currentType === type || iconClick)
        ) {
            cloneLookRequest[lowerType] = garmentsHistory[type]
            dispatch(
                HandleLookRequest({
                    lookRequest: cloneLookRequest,
                    keepAdditional: true,
                    focus: type,
                })
            )
        }

        // If it's not an additionnal garment and we are not currently on that type but we keep the other garments
        if (isPrimaryTypeValue && type !== currentType && !isOptionalType) {
            const mandatoryTypes = getTypesFromPrimariesAndSecondaries(type)
            const mandatoryObject = {}
            mandatoryTypes.forEach((mandatoryType: string) => {
                if (
                    mandatoryType !== type &&
                    !lookRequest.optional_types?.includes(mandatoryType)
                ) {
                    mandatoryObject[mandatoryType.toLowerCase()] = garmentsHistory[mandatoryType]
                }
            })

            const newRequest = {
                [lowerType]: garmentsHistory[type],
                ...mandatoryObject,
            }
            dispatch(
                HandleLookRequest({ lookRequest: newRequest, keepAdditional: true, focus: type })
            )
        }
    }

    // Basic method when clicking on the type in a header
    function typeClick(value: string) {
        // We only call a new look on the swipe page when we change the type
        if (['/swipe', '/catalog'].includes(location.pathname)) {
            // If we are not currently on that type
            if (value !== currentType) {
                // The default type click is handled as in the stylebarClick
                styleBarTypeClick(value)
            }
        }

        dispatch(FetchTypeGarmentAction(value))
    }

    return { typeClick, styleBarTypeClick }
}
