// GARMENT
export const SET_QUERY_GARMENT_TYPE = 'SET_QUERY_GARMENT_TYPE'
export const FETCH_ALL_GARMENTS = 'FETCH_ALL_GARMENTS'
export const FETCH_TYPE_GARMENT = 'FETCH_TYPE_GARMENT'
export const FETCH_GARMENT_DETAIL = 'FETCH_GARMENT_DETAIL'
export const SET_CART_ADDED = 'SET_CART_ADDED'
export const SET_DETAIL_ID = 'SET_DETAIL_ID'

// LOOK
export const INC_LOOK_LOADING = 'INC_LOOK_LOADING'
export const SET_QUERY_LOOK_ID = 'SET_QUERY_LOOK_ID'
export const FETCH_CURRENT_LOOK = 'FETCH_CURRENT_LOOK'
export const SET_LOOK_INDEX = 'SET_LOOK_INDEX'
export const FETCH_RECOMMENDATIONS = 'FETCH_RECOMMENDATIONS'
export const SET_LOOK_REQUEST = 'SET_LOOK_REQUEST'
export const SET_GARMENT_HISTORY = 'SET_GARMENT_HISTORY'
export const SET_USER_TUCK = 'SET_USER_TUCK'

// ERROR
export const SET_ERROR = 'SET_ERROR'
