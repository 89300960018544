import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface MonitorInfo {
    init_timestamp: number
    api_result_timestamp: number
    from?: string
}

interface LoadingMonitoringSlice {
    [key: string]: MonitorInfo
}

const initialState: LoadingMonitoringSlice = {}

// Slice storing the garments by types and with pagination
export const loadingMonitoringSlice = createSlice({
    initialState,
    name: 'loadingMonitoringSlice',
    reducers: {
        addStartLoading: (
            state: LoadingMonitoringSlice,
            action: PayloadAction<{
                src: string
                init_timestamp: number
                api_result_timestamp: number
                from?: string
            }>
        ) => {
            state[action.payload.src] = {
                init_timestamp: action.payload.init_timestamp,
                api_result_timestamp: action.payload.api_result_timestamp,
                from: action.payload.from,
            }
        },
        removeLoading: (state: LoadingMonitoringSlice, action: PayloadAction<string>) => {
            delete state[action.payload]
        },
    },
})

// ----- Actions -----
export const { addStartLoading, removeLoading } = loadingMonitoringSlice.actions

export default loadingMonitoringSlice.reducer
