import React, { useEffect } from 'react'
import { useAppSelector } from 'src/store'
import { trackEvent } from 'src/utils/tracking'

const LookTracking: React.FunctionComponent = () => {
    const look = useAppSelector((state) => state.look?.current)

    useEffect(() => {
        if (look) {
            trackEvent('Outfit Created', look, 'Outfit')
        }
        // eslint-disable-next-line
    }, [look])

    return null
}

export default LookTracking
