import { combineReducers } from 'redux'

import garment from './garment'
import look from './look'
import error from './error'
import { apiGarment } from '../api/api-garment'
import databaseSlice from '../slices/databaseSlice'
import { apiExperience } from '../api/api-experience'
import layoutSlice from '../slices/layoutSlice'
import { apiLook } from '../api/api-look'
import loadingMonitoringSlice from '../slices/loadingMonitoringSlice'
import { apiPrice } from '../api/api-price'
import profileSlice from '../slices/profileSlice'
import { apiProfile } from '../api/api-profile'
import favoritesSlice from '../slices/favoritesSilce'
import { apiModel } from '../api/api-model'
import modelSlice from '../slices/modelSlice'

export default combineReducers({
    model: modelSlice,
    garment,
    look,
    profile: profileSlice,
    favorites: favoritesSlice,
    error,
    [apiGarment.reducerPath]: apiGarment.reducer,
    [apiExperience.reducerPath]: apiExperience.reducer,
    [apiLook.reducerPath]: apiLook.reducer,
    [apiPrice.reducerPath]: apiPrice.reducer,
    [apiProfile.reducerPath]: apiProfile.reducer,
    [apiModel.reducerPath]: apiModel.reducer,
    databaseSlice: databaseSlice,
    layoutSlice: layoutSlice,
    loadingMonitoringSlice: loadingMonitoringSlice,
})
