import { ArrowLeftOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import React, { useEffect, useRef, useState } from 'react'
import CardItemHorizontal from 'src/components/card/CardItemHorizontal'
import { useAppSelector } from 'src/store'
import {
    getAllGarmentsForType,
    getCurrentActiveFiltersForType,
} from 'src/store/slices/databaseSlice'
import { removeGarment } from 'src/utils/garment'
import { getCustomMinusIcon } from 'src/utils/icon'
import { handleCatalogScroll } from 'src/utils/scroll'
import { trackEvent } from 'src/utils/tracking'
import useCustomTranslation from 'src/utils/translation'
import useTypeFunctions from 'src/utils/typeMethods-hook'
import { removeVariantFilter } from 'src/utils/variantHelpers'

interface CatalogHorizontalProps {
    height?: number
}

export default function CatalogHorizontal(props: CatalogHorizontalProps) {
    const { height } = props
    const { t } = useCustomTranslation()
    const { isPrimaryOrSecondaryType } = useTypeFunctions()

    const garmentType = useAppSelector((state) => state.garment?.type)
    const allGarments = useAppSelector((state) => getAllGarmentsForType(state, garmentType))
    const lookRequest = useAppSelector((state) => state.look.request)
    const activeFilters = useAppSelector((state) =>
        getCurrentActiveFiltersForType(state, garmentType)
    )

    const containerRef = useRef<HTMLDivElement>()

    const [refScrollLeft, setRefScrollLeft] = useState<number>(0)
    const [refClientWidth, setRefClientWidth] = useState<number>(0)
    const [refScrollWidth, setRefScrollWidth] = useState<number>(0)

    // Store the different content width values in state so it update the check on the arrows condition
    const updateStylBarValues = (content: HTMLElement) => {
        setRefScrollLeft(Math.ceil(content.scrollLeft))
        setRefScrollWidth(content.scrollWidth)
        setRefClientWidth(content.clientWidth)
    }

    const onLeftArrowClick = () => {
        containerRef?.current.scrollTo({
            left:
                refScrollLeft < containerRef.current.clientWidth
                    ? 0
                    : refScrollLeft - containerRef.current.clientWidth,
            behavior: 'smooth',
        })
    }

    const onRightArrowClick = () => {
        containerRef?.current.scrollTo({
            left:
                refScrollLeft > refScrollWidth - refClientWidth
                    ? refScrollWidth
                    : refScrollLeft + refClientWidth,
            behavior: 'smooth',
        })
    }

    const handleVariantBackClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        trackEvent(
            'Variant Back Clicked',
            {
                look: lookRequest,
                show_type: garmentType,
                show_value: lookRequest[garmentType] ? 'off' : 'on',
            },
            'Catalog'
        )
        removeVariantFilter()
    }

    const handleRemoveClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        trackEvent(
            'Show Type Changed',
            {
                look: lookRequest,
                show_type: garmentType,
                show_value: lookRequest[garmentType] ? 'off' : 'on',
            },
            'Catalog'
        )
        removeGarment(garmentType)
    }

    const handleHorizontalCatalogScroll = (e: React.UIEvent<HTMLDivElement>) => {
        updateStylBarValues(e.currentTarget)
        handleCatalogScroll(e, true)
    }

    useEffect(() => {
        if (!containerRef.current) {
            return undefined
        }

        let interval = null
        const content = containerRef.current

        // Handle Resize of the window
        function handleResize() {
            if (content) {
                clearInterval(interval)
                interval = null
                updateStylBarValues(content)
            }
        }

        interval = setInterval(() => {
            handleResize()
        }, 100)
        window.addEventListener('resize', handleResize)
        return () => {
            if (interval) {
                clearInterval(interval)
            }

            window.removeEventListener('resize', handleResize)
        }
    }, [containerRef])

    // ---- Update StylebarValues when container exists or when the list of garment changes (ie: we filter the list) ----
    useEffect(() => {
        if (!containerRef.current) {
            return undefined
        }

        // ---- Direct update when we have the stylebarelement ----
        updateStylBarValues(containerRef.current)
    }, [containerRef, allGarments])

    useEffect(() => {
        const lookGarment = lookRequest[garmentType.toLowerCase()]
        if (!lookGarment) {
            return
        }

        document.getElementById(lookGarment.garment_id)?.scrollIntoView({ behavior: 'smooth' })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [garmentType])

    if (!garmentType) {
        return <div className={`catalog--horizontal--container`} />
    }

    return (
        <div
            className={`catalog--horizontal--container`}
            onScroll={handleHorizontalCatalogScroll}
            ref={containerRef}
            style={{ maxHeight: height ? height - 35 : undefined }}
        >
            {refScrollLeft !== 0 && (
                <div className='style-bar--arrow-container-left' onClick={onLeftArrowClick}>
                    <LeftOutlined />
                </div>
            )}
            {Object.keys(activeFilters).includes('garment_variant_ids') && (
                <div className='card-item-horizontal--container' onClick={handleVariantBackClick}>
                    <ArrowLeftOutlined
                        style={{
                            opacity: 0.7,
                            color: 'gray',
                            fontSize: 50,
                            height: '65%',
                            justifyContent: 'center',
                        }}
                    />
                    <div className='catalog--horizontal--remove-text'>
                        {t('product.return_list')}
                    </div>
                </div>
            )}
            {(!isPrimaryOrSecondaryType(garmentType) ||
                lookRequest?.optional_types?.includes(garmentType)) &&
                !Object.keys(activeFilters).includes('garment_variant_ids') && (
                    <div
                        className='card-item-horizontal--container card-item-horizontal--remove-container'
                        onClick={handleRemoveClick}
                    >
                        {getCustomMinusIcon({
                            opacity: 0.7,
                            color: 'gray',
                            fontSize: 50,
                            height: '65%',
                            justifyContent: 'center',
                        })}
                        <div className='catalog--horizontal--remove-text'>
                            {t('product.remove')}
                        </div>
                    </div>
                )}
            {allGarments?.map((item: Models.Garment) => (
                <CardItemHorizontal data={item} key={item.garment_id} />
            ))}
            {refScrollLeft + refClientWidth < refScrollWidth && (
                <div className='style-bar--arrow-container-right' onClick={onRightArrowClick}>
                    <RightOutlined />
                </div>
            )}
        </div>
    )
}
