import React from 'react'
import { useAppSelector } from 'src/store'
import { getAllGarmentsObjectForType } from 'src/store/slices/databaseSlice'
import useCustomTranslation from 'src/utils/translation'

export default function ItemCounter() {
    const { t } = useCustomTranslation()
    const garmentType = useAppSelector((state) => state.garment.type)
    const garmentsObject = useAppSelector((state) =>
        getAllGarmentsObjectForType(state, garmentType)
    )
    return (
        <>
            {garmentsObject && garmentsObject.current && (
                <div className='item-counter'>
                    {`${garmentsObject.current.total_count} ${t(
                        `layout.item${garmentsObject.current.total_count > 1 ? 's' : ''}`
                    )}`}
                </div>
            )}
        </>
    )
}
