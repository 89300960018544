import { Tabs } from 'antd'
import React from 'react'
import { useAppSelector } from 'src/store'
import { getCurrentActiveFiltersForType } from 'src/store/slices/databaseSlice'
import useCustomTranslation from 'src/utils/translation'

interface SubHeaderProps {
    localFilters: { [key: string]: Models.Facet[] } | null
    onlyOneSubCategory?: boolean
    onClick: (facetKey: string) => void
    overrideClassName?: string
}

export default function SubHeader(props: SubHeaderProps) {
    const { t } = useCustomTranslation()

    const garmentType = useAppSelector((state) => state.garment?.type)
    const company = useAppSelector((state) => state.profile?.company)
    const activeFilters = useAppSelector((state) =>
        getCurrentActiveFiltersForType(state, garmentType)
    )

    const { localFilters, onlyOneSubCategory, onClick, overrideClassName } = props

    const categoryFacet = company.garment_category_facets[garmentType]

    return (
        <div
            className={`menu--sub-container menu--container-overflow ${
                overrideClassName ? overrideClassName : ''
            }`}
        >
            {localFilters && localFilters[garmentType] && (
                <Tabs
                    activeKey={
                        onlyOneSubCategory
                            ? activeFilters[categoryFacet][0]
                            : activeFilters && Object.keys(activeFilters).length > 0
                            ? ''
                            : 'ALL'
                    }
                    onTabClick={onClick}
                    items={
                        localFilters[garmentType] &&
                        localFilters[garmentType][categoryFacet] && [
                            { key: 'ALL', label: t('layout.all') },
                            ...localFilters[garmentType][categoryFacet].data.map((localData) => {
                                return {
                                    key: localData.value,
                                    label: localData.label || localData.value,
                                }
                            }),
                        ]
                    }
                />
            )}
        </div>
    )
}
