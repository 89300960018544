import { getQueryValue } from './query'

export const inWKWebView = () => {
    return !!(window['webkit'] && window['webkit']['messageHandlers'])
}

export const sendWKScriptMessage = (bridge, payload) => {
    window['webkit']['messageHandlers'][bridge].postMessage(payload)
}

export const sendWKGotoCart = () => {
    // ---- Lululemon ios ----
    if (inWKWebView() && ['lululemon.com'].indexOf(getQueryValue('domain')) !== -1) {
        // ---- Send message ----
        sendWKScriptMessage('bridge', {
            event_id: 'checkout',
        })

        return true
    }

    return false
}

export const openModelProductDetail = (garment) => {
    // ---- Lululemon ios ----
    if (inWKWebView() && ['lululemon.com'].indexOf(getQueryValue('domain')) !== -1) {
        // ---- Send message ----
        sendWKScriptMessage('bridge', {
            event_id: 'product_details',
            productId: garment.product_external_id,
        })

        return true
    }

    return false
}

export const sendWKGotoProductDetail = (garment) => {
    // ---- Lululemon ios ----
    if (inWKWebView() && ['lululemon.com'].indexOf(getQueryValue('domain')) !== -1) {
        // ---- Send message ----
        sendWKScriptMessage('bridge', {
            event_id: 'product_details',
            productId: garment.product_external_id,
        })

        return true
    }

    return false
}
