import React, { useEffect } from 'react'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { trackPage } from 'src/utils/tracking'
import { useAppSelector } from 'src/store'
import useCustomTranslation from 'src/utils/translation'
import { toggleModelModal } from 'src/store/slices/layoutSlice'
import { useDispatch } from 'react-redux'
import CloseButton from 'src/components/button/CloseButton'
import CarouselModel from 'src/components/carousel/carouselModel'

const ModelPage: React.FunctionComponent = () => {
    const { t } = useCustomTranslation()
    const dispatch = useDispatch()
    const models = useAppSelector((state) => state.model.all)

    useEffect(() => {
        trackPage()
        // eslint-disable-next-line
    }, [])

    // ---- We need the models to show the content of the model page ----
    if (!models) {
        return null
    }

    return (
        <div className='model--container'>
            <div className='model--header'>
                <div className='model--title'>
                    {`${t('look.change_model')}`}
                    <span className='model--title-count'>{` (${
                        models ? models.total_count : 0
                    })`}</span>
                </div>
                <CloseButton
                    className='modal--close'
                    onClick={() => dispatch(toggleModelModal())}
                />
            </div>

            <div className='model--content'>
                <CarouselModel />
            </div>
        </div>
    )
}

export default ModelPage
