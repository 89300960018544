import { Tabs } from 'antd'
import React, { useMemo } from 'react'
import { useAppSelector } from 'src/store'
import { useGetExperiencesQuery } from 'src/store/api/api-experience'
import { getQueryValue, updateSearchString } from 'src/utils/query'
import { trackEvent } from 'src/utils/tracking'

interface ExperienceTabsProps {
    className?: string
}

export default function ExperienceTabs(props: ExperienceTabsProps) {
    const company = useAppSelector((state) => state.profile.company)
    const look = useAppSelector((state) => state.look?.request)

    const { className } = props

    const { data: experiences } = useGetExperiencesQuery()

    const experienceId = getQueryValue('experience_id')

    const foundExperience = useMemo(() => {
        if (experiences && company) {
            return experiences.find((exp) => exp.experience_id === experienceId)
        }

        return null
    }, [company, experiences, experienceId])

    const handleClickExperience = (newExperienceId: string) => {
        if (!experiences) {
            return
        }
        if (experienceId == newExperienceId) {
            return
        }

        const newExperience = experiences.find((exp) => exp.experience_id === newExperienceId)
        trackEvent(
            'Multi-Experience Selected',
            newExperience
                ? {
                      multi_experience_id: newExperience.experience_id,
                      multi_experience_name: newExperience.experience_name,
                  }
                : {},
            'Menu'
        )

        window.open(
            '/swipe?' +
                updateSearchString({
                    experience_id: newExperienceId,
                    look_id: null,
                    type: null,
                    model_id: look?.model?.model_id,
                }),
            '_self'
        )
    }

    return (
        <>
            {company.enable_switch_experiences === true && foundExperience && (
                <Tabs
                    activeKey={foundExperience.experience_id}
                    className={`tabs--experience ${className ? className : ''}`}
                    items={experiences.map((localExperience) => {
                        return {
                            label: localExperience.experience_name,
                            key: localExperience.experience_id,
                        }
                    })}
                    onTabClick={(activeKey) => handleClickExperience(activeKey)}
                />
            )}
        </>
    )
}
