import { useMemo } from 'react'
import { getIsSizeGroup } from './garment'
import { useAppSelector } from 'src/store'
import { getPricesForType } from 'src/store/slices/databaseSlice'

export default function useGarmentMethodsHook(garment?: Models.GarmentDetail | Models.Garment) {
    const garmentPrices = useAppSelector((state) =>
        getPricesForType(state, garment?.garment_type || '')
    )

    const hasMultiPrice = useMemo(() => {
        if (!garment || getIsSizeGroup(garment.product_sizes)) {
            return false
        }

        const sizeWithPrice = (garment.product_sizes as Models.OldGarmentSize[]).find(
            (size) => size.price
        )

        return !!sizeWithPrice
    }, [garment])

    const convertedPrice = useMemo(() => {
        if (!garmentPrices || !garment) {
            return null
        }

        return garmentPrices.find((price) => price.garment_id === garment.garment_id)
    }, [garmentPrices, garment])

    return { hasMultiPrice, convertedPrice }
}
