export const calculSlickListOffset = (
    imgWidth: number,
    nbSlides: number,
    containerWidth: number
) => {
    return (imgWidth * nbSlides - containerWidth) / 2
}

// ---- Handle the offset we need to add to assure the look is always visible ----
export const handleSlickListOffset = (garmentType: string) => {
    const swipeContainer = document.getElementById('layoutScrollableContent')
    const slickListElements = document.getElementsByClassName(`carousel-swipe-${garmentType}`)

    // ---- We need the swipeContainer width and the correct carousel to handle the slick offset ----
    if (!swipeContainer || slickListElements.length === 0) {
        return
    }

    // ---- We assume that the width of an image is 1/3 of the lookContainer height ----
    const imgWidth = swipeContainer.clientHeight / 3
    const slickListElement = slickListElements[0].getElementsByClassName(
        'slick-list'
    )[0] as HTMLDivElement
    const slidesActive = slickListElement.getElementsByClassName('slick-active')
    const nbSlides = slidesActive.length
    const needMargin = imgWidth * nbSlides > swipeContainer.clientWidth

    if (needMargin) {
        const newMarginValue = calculSlickListOffset(imgWidth, nbSlides, swipeContainer.clientWidth)

        // ---- We had a the offset as negative horizontal margin to keep the look visible ----
        slickListElement.style.margin = `0 -${newMarginValue}px`
    } else {
        slickListElement.style.margin = ''
    }
}
