export const ENVIRONMENTS: any = {
    development: process.env.NODE_ENV === 'development',
    production: process.env.NODE_ENV === 'production',
}

export const API_ENDPOINT: string = (
    process.env.API_ENDPOINT ||
    process.env.REACT_APP_API_ENDPOINT ||
    ''
).replace('[QUERY_HOSTNAME]', window.location.hostname)

export const DEBUG_MODE: boolean =
    process.env.DEBUG_MODE === 'true' || process.env.REACT_APP_DEBUG_MODE === 'true'

export function getCommonHeaders(): Types.ValueMap<string> {
    return {
        'Content-Type': 'application/json',
        'Accept-Language': 'fr',
    }
}

export function getFormDataHeaders(): Types.ValueMap<string> {
    return {
        'Content-Type': 'multipart/form-data',
    }
}

// ---- TYPE CONSTANTS ----
export const TYPE_AS_DRESS = ['DRESS', 'BODY']
export const PRIMARY_TYPES = [['TOP', 'BOTTOM'], ...TYPE_AS_DRESS]

// ---- Look Loading Monitoring constants ----
export const SWIPE = 'swipe'
export const CATALOG = 'catalog'
export const FAVORITE = 'favorite'
