import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getQueryValue } from 'src/utils/query'

const initialState: State.Profile = {
    company: null,
    parentHeight: getQueryValue('parentHeight'),
    headerHidden: false,
}

// Slice storing the client config and the global infos
export const profileSlice = createSlice({
    initialState,
    name: 'profile',
    reducers: {
        addCompany: (state: State.Profile, action: PayloadAction<Models.CompanyProfile>) => {
            state.company = action.payload
        },
        setParentHeight: (state: State.Profile, action: PayloadAction<number>) => {
            state.parentHeight = action.payload
        },
        setHeaderHidden: (state: State.Profile, action: PayloadAction<boolean>) => {
            state.headerHidden = action.payload
        },
    },
})

// ----- Actions -----
export const { addCompany, setParentHeight, setHeaderHidden } = profileSlice.actions

export default profileSlice.reducer
