import React from 'react'

export const customSpinner = (
    <div className='veil eileen-custom-spinner'>
        <div className='underlay'></div>
        <div className='spinner'>
            <div className='orbit-one'>
                <div className='dot one'></div>
            </div>
            <div className='orbit-two'>
                <div className='dot two'></div>
            </div>
        </div>
    </div>
)
