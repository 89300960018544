import React from 'react'
import useGarmentMethodsHook from 'src/utils/garmentMethodsHook'
import useCustomTranslation from 'src/utils/translation'

interface StartingAtProps {
    garment: Models.GarmentDetail | Models.Garment
    style?: React.CSSProperties
}

export default function StartingAt(props: StartingAtProps) {
    const { t } = useCustomTranslation()
    const { hasMultiPrice } = useGarmentMethodsHook(props.garment)

    if (!hasMultiPrice) {
        return null
    }

    return (
        <div className='startingat' style={props.style}>
            {t('layout.starting_at')}
        </div>
    )
}
