import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { loadFromLocalStorageArray, saveIntoLocalStorage } from 'src/utils/localStorageHelpers'
import { getQueryValue } from 'src/utils/query'

const domain = getQueryValue('domain') || 'undefined'
const experienceId = getQueryValue('experience_id')
const storageBaseKey = domain + (experienceId ? '_' + experienceId : '')

const initialState: State.Favorites = {
    looks: loadFromLocalStorageArray(`${storageBaseKey}.favorites.looks`),
    garments: loadFromLocalStorageArray(`${storageBaseKey}.favorites.garments`),
    isOutfit: false,
    storageBaseKey: storageBaseKey,
}

// Slice storing the favorites data
export const favoritesSlice = createSlice({
    initialState,
    name: 'favorites',
    reducers: {
        addFavoriteGarments: (state: State.Favorites, action: PayloadAction<Models.Garment[]>) => {
            state.garments = saveIntoLocalStorage(
                `${state.storageBaseKey}.favorites.garments`,
                action.payload
            )
            state.isOutfit = false
        },
        removeFavoriteGarment: (state: State.Favorites, action: PayloadAction<string>) => {
            // ---- Remove elements with payload ID ----
            const newGarments = state.garments.filter((item) => item.garment_id !== action.payload)

            state.garments = saveIntoLocalStorage(
                `${state.storageBaseKey}.favorites.garments`,
                newGarments
            )
            state.isOutfit = false
        },
        addFavoriteOutfits: (state: State.Favorites, action: PayloadAction<Models.Look[]>) => {
            state.looks = saveIntoLocalStorage(
                `${state.storageBaseKey}.favorites.looks`,
                action.payload
            )
            state.isOutfit = true
        },
        removeFavoriteOutfit: (state: State.Favorites, action: PayloadAction<string>) => {
            // ---- Remove elements with payload ID ----
            const newLooks = state.looks.filter((item) => item.look_id !== action.payload)

            state.looks = saveIntoLocalStorage(`${state.storageBaseKey}.favorites.looks`, newLooks)
            state.isOutfit = true
        },
        changeFavoriteIsOutfit: (state: State.Favorites, action: PayloadAction<boolean>) => {
            state.isOutfit = action.payload
        },
        changeStorageBaseKey: (state: State.Favorites, action: PayloadAction<string>) => {
            state.storageBaseKey = `${storageBaseKey}.${action.payload}`
            state.looks = loadFromLocalStorageArray(
                `${storageBaseKey}.${action.payload}.favorites.looks`
            )
            state.garments = loadFromLocalStorageArray(
                `${storageBaseKey}.${action.payload}.favorites.garments`
            )
        },
    },
})

// ----- Actions -----
export const {
    addFavoriteGarments,
    removeFavoriteGarment,
    addFavoriteOutfits,
    removeFavoriteOutfit,
    changeFavoriteIsOutfit,
    changeStorageBaseKey,
} = favoritesSlice.actions

export default favoritesSlice.reducer
