import { useAppSelector } from 'src/store'
import { getQueryGender } from './query'
import { useGetModelsMutation } from 'src/store/api/api-model'

// Custom hook to inject default param inside the getModels mutation
export default function useCustomGetModels() {
    const companyGenders = useAppSelector((state) => state.profile?.company?.genders)
    const seed = useAppSelector((state) => state.profile?.company?.seed)
    const [getModelsTrigger, result] = useGetModelsMutation({
        fixedCacheKey: 'models-mutation',
    })

    function customModelsTrigger(params?: API.Req.GetModels) {
        const genderFilterValue: string = getQueryGender(companyGenders)

        return getModelsTrigger({
            ...(genderFilterValue && { gender: genderFilterValue }),
            ...{ seed },
            ...params,
        })
    }

    return [customModelsTrigger, result] as const
}
