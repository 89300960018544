import React from 'react'
import { Button, Image } from 'antd'
import { getCustomMinusIcon, getCustomPlusIcon } from 'src/utils/icon'

interface StyleBarTileProps {
    image: string
    title: string
    overrideClassName?: string
    onClick?: (e: React.MouseEvent) => void
    hidden?: boolean
    noIcon?: boolean
    zoomImage?: boolean
    checkboxClick?(e: React.MouseEvent): void
}

export default function StyleBarTile(props: StyleBarTileProps) {
    const { image, title, overrideClassName, onClick, noIcon, zoomImage, hidden, checkboxClick } =
        props
    return (
        <div
            onClick={onClick ? onClick : undefined}
            className={`style-bar--element ${overrideClassName ? overrideClassName : ''}`}
        >
            <div
                className={`style-bar--image-container${
                    zoomImage ? ' style-bar--image-container-zoom' : ''
                }`}
            >
                {!noIcon && (
                    <>
                        {hidden ? (
                            <div className='style-bar--plus-container'>
                                <Button
                                    className='button--stylebar-icon'
                                    icon={getCustomPlusIcon()}
                                    type='text'
                                />
                            </div>
                        ) : (
                            <div className='style-bar--remove-container'>
                                <Button
                                    className='button--stylebar-icon'
                                    icon={getCustomMinusIcon()}
                                    onClick={checkboxClick}
                                    type='text'
                                />
                            </div>
                        )}
                    </>
                )}
                <Image
                    preview={false}
                    src={image}
                    className={zoomImage ? 'style-bar--image-zoom' : 'style-bar--image'}
                    rootClassName='style-bar--root-image'
                    draggable={false}
                />
            </div>
            <div className='style-bar--title'>{title}</div>
        </div>
    )
}
