import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: State.ModelProduct = {
    all: null,
}

// Slice storing the models
export const modelSlice = createSlice({
    initialState,
    name: 'model',
    reducers: {
        addModels: (state: State.ModelProduct, action: PayloadAction<Models.Models>) => {
            state.all = action.payload
        },
    },
})

// ----- Actions -----
export const { addModels } = modelSlice.actions

export default modelSlice.reducer
