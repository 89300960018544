import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Image as AntdImage } from 'antd'
import { resizeImage } from 'src/utils/image'
import { useAppSelector } from 'src/store'
import Loader from 'src/components/Loader'
import { removeLoading } from 'src/store/slices/loadingMonitoringSlice'
import { trackEvent } from 'src/utils/tracking'
import useTypeFunctions from 'src/utils/typeMethods-hook'
import { HandleLookRequest } from 'src/store/actions/look'

// ---- External expand values needed to throttle the expand when resizing ----
let oldExpandHorizontalGlobalValue: number | null = null

// ---- Local var of the look model id to check if we changed the model id or not ----
let localModelId: string | null = null

interface LookContainerProps {
    noLoader?: boolean
}

const LookContainer: React.FunctionComponent<LookContainerProps> = (props) => {
    const dispatch = useDispatch()
    const { isPrimaryOrSecondaryType } = useTypeFunctions()

    const { noLoader } = props

    const currentLook = useAppSelector((state) => state.look?.current)
    const lookLoading = useAppSelector((state) => state.look?.loading)
    const lookIndex = useAppSelector((state) => state.look?.index)
    const lookRatio = useAppSelector((state) => state.profile.company.look_image_ratio)
    const loadingMonitoring = useAppSelector((state) => state.loadingMonitoringSlice)
    const company = useAppSelector((state) => state.profile.company)

    const [currentSrc, setCurrentSrc] = useState(null)
    const [currentLoaded, setCurrentLoaded] = useState(false)
    const [oldSrc, setOldSrc] = useState(null)
    const [oldOpacity, setOldOpacity] = useState(0)
    // ---- Expand src is only used once per pose or resize then we use currentSrc to speed up loading ----
    const [expandSrc, setExpandSrc] = useState<string>()

    // ---- State for Expand value still needed to trigger the useEffect and load a new image when expanding ----
    const [expandHorizontalState, setExpandHorizontalState] = useState<number>()
    // ---- Need ref because State is not updated in resize function ----
    const expandHorizontalValue = React.useRef<number>()

    const startTimeout: any = useRef()
    const transitionTimeout: any = useRef()

    // ---- Ref we need to know which retry we have done: no_additional = removed all aditionnal garment, random = removed all -----
    const retriedState = useRef<string>()

    const transitionTime = 500

    const isLoading = !currentLoaded || !!lookLoading

    // ---- Handle the expandSrc and currentSrc according to the img url ----
    const setCorrectSrc = (newSrc: string) => {
        // ---- We reset the current if we set a new expand src ----
        if (newSrc.match('&expand')) {
            setExpandSrc(newSrc)
            setCurrentSrc(null)

            return
        }

        // ---- If it's not an expand we set the current ----
        setCurrentSrc(newSrc)

        return
    }

    const handleOnLoad = (loadedSrc, force = false) => {
        if (loadedSrc === currentSrc || force) {
            // ---- Reset the retried state if we successfully loaded the image ----
            retriedState.current = ''

            // ---- Handle Loading Monitoring ----
            const finishedTimestamp = new Date().getTime()
            const monitoring = Object.keys(loadingMonitoring).find((key) =>
                loadedSrc?.includes(key)
            )
            if (monitoring) {
                trackEvent(
                    'Monitor Outfit Load',
                    [
                        currentLook,
                        {
                            monitor_total_ms:
                                finishedTimestamp - loadingMonitoring[monitoring].init_timestamp,
                            monitor_api_ms:
                                loadingMonitoring[monitoring].api_result_timestamp -
                                loadingMonitoring[monitoring].init_timestamp,
                            monitor_image_ms:
                                finishedTimestamp -
                                loadingMonitoring[monitoring].api_result_timestamp,
                            load_cache: !!currentLook.cache,
                        },
                        loadingMonitoring[monitoring].from && {
                            load_from: loadingMonitoring[monitoring].from,
                        },
                    ],
                    'Monitoring'
                )
                dispatch(removeLoading(monitoring))
            }

            setCorrectSrc(loadedSrc)
            setCurrentLoaded(true)
            startTimeout.current = setTimeout(() => {
                setOldOpacity(0)
                transitionTimeout.current = setTimeout(() => setOldSrc(null), transitionTime)

                // ---- If old value is null or if we incremented the expand before we update the old value  ----
                if (
                    !oldExpandHorizontalGlobalValue ||
                    oldExpandHorizontalGlobalValue !== expandHorizontalValue.current
                ) {
                    oldExpandHorizontalGlobalValue = expandHorizontalValue.current

                    // ---- Update state if the expand ref is different so we load the next image ----
                    if (expandHorizontalValue.current !== expandHorizontalState) {
                        setExpandHorizontalState(expandHorizontalValue.current)
                    }
                }
            }, 20)
        }
    }

    const handleImgError = (e: ErrorEvent) => {
        // ---- Monitoring stop ----
        const monitoring = Object.keys(loadingMonitoring).find((key) =>
            (e.target as HTMLImageElement).src?.includes(key)
        )
        if (monitoring) {
            dispatch(removeLoading(monitoring))
        }

        // ---- Var init ----
        const lookClone: Models.Look = JSON.parse(JSON.stringify(currentLook))
        let removedAdditionnalGarment = false
        const emptyLookRequest = {}

        // ---- For each garment type from the config we update the lookClone and emptyLookRequest ----
        company.garment_types.forEach((companyGarmentType) => {
            const lowercaseType = companyGarmentType.toLowerCase()

            // ---- Set this garment type to null in the emptyLookRequest ----
            emptyLookRequest[lowercaseType] = null

            // ---- If it's a primary or secondary type or if we don't have this garment type on the look we return ----
            if (isPrimaryOrSecondaryType(companyGarmentType) || !lookClone[lowercaseType]) {
                return
            }

            // ---- We set the removed boolean to true and remove this garment type on the look ----
            removedAdditionnalGarment = true
            delete lookClone[lowercaseType]
        })

        // ---- If we removed a garment we retry without additional garments and trigger the look request ----
        if (removedAdditionnalGarment) {
            retriedState.current = 'no_additional'
            dispatch(HandleLookRequest({ lookRequest: lookClone }))

            return
        }

        // ---- If we didn't remove an additional garment and we did not yet try the random look, we try it ----
        if (retriedState.current !== 'random') {
            retriedState.current = 'random'
            dispatch(HandleLookRequest({ lookRequest: emptyLookRequest }))

            return
        }

        // ---- Clean up after every retry failed ----
        setCurrentLoaded(true)
        setCorrectSrc('')
    }

    const handleImgTransition = (src: string, img: HTMLImageElement, customOnLoad) => {
        clearTimeout(startTimeout.current)
        clearTimeout(transitionTimeout.current)
        ;(window as any).usePreloadImagesData = {}
        if (src !== undefined && src !== null) {
            img.src = src
            ;(window as any).usePreloadImagesData[src] = img
            if (!img.complete) {
                img.addEventListener('load', customOnLoad)
                img.addEventListener('error', handleImgError)
            }

            if (currentSrc !== null) {
                setOldSrc(currentSrc)
                setOldOpacity(0.99)
            }

            setCorrectSrc(src)
            setCurrentLoaded(false)

            if (img.complete) {
                handleOnLoad(src, true)
            }
        }
    }

    useEffect(() => {
        const handleResize = () => {
            const container = document.getElementById('layoutSwipeContentId')
            if (container && container.clientHeight !== 0) {
                // ---- Width of image without expand ----
                const noExpandWidth = container.clientHeight * (lookRatio || 1)

                // ---- Check if expand is init ----
                if (!expandHorizontalValue.current) {
                    const expandNeeded = Math.ceil(container.clientWidth / noExpandWidth)
                    expandHorizontalValue.current = expandNeeded
                    setExpandHorizontalState(expandNeeded)

                    return
                }

                // ---- If the image width is smaller than the container width + threshold we expand it (if the old expand img already loaded) ----
                if (noExpandWidth * expandHorizontalValue.current <= container.clientWidth + 150) {
                    // ---- Update state if we are not loading ----
                    if (oldExpandHorizontalGlobalValue === expandHorizontalValue.current) {
                        setExpandHorizontalState((current) => current + 1)
                    }

                    // ---- Update ref in anycase so when the onload finish it will sync the state to the ref value and load the next expand ----
                    expandHorizontalValue.current = expandHorizontalValue.current + 1
                }
            }
        }

        const resizeObserver = new ResizeObserver(() => {
            handleResize()
        })

        const interval = setInterval(() => {
            const container = document.getElementById('layoutSwipeContentId')
            if (container && container.clientHeight !== 0) {
                clearInterval(interval)

                resizeObserver.observe(container)
            }
        }, 100)

        return () => {
            resizeObserver.disconnect()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!expandHorizontalState || !currentLook) {
            return undefined
        }

        const src =
            `${resizeImage(currentLook.image_urls[lookIndex], {
                width: 800,
            })}&expand=1x${expandHorizontalState}` + (lookRatio ? `&cratio=${lookRatio}` : '')
        const customHandleOnLoad = handleOnLoad.bind(this, src)

        const img = new Image()
        handleImgTransition(src, img, customHandleOnLoad)

        return () => {
            if (src !== undefined && src !== null) {
                clearTimeout(startTimeout.current)
                clearTimeout(transitionTimeout.current)
                img.removeEventListener('load', customHandleOnLoad)
                img.removeEventListener('error', handleImgError)
            }
        }
        // eslint-disable-next-line
    }, [lookIndex, expandHorizontalState])

    useEffect(() => {
        // ---- We don't load anything if we don't have a look ----
        if (!currentLook) {
            return undefined
        }

        // ---- We don't load anything if the expand is not set yet but we update the localModelId ----
        if (!expandSrc) {
            localModelId = currentLook.model?.model_id

            return undefined
        }

        let src =
            resizeImage(currentLook.image_urls[lookIndex], {
                width: 800,
            }) + (lookRatio ? `&cratio=${lookRatio}` : '')

        // ---- If the model id changed we need to reload an expand ----
        if (currentLook && localModelId && localModelId !== currentLook.model?.model_id) {
            src =
                `${resizeImage(currentLook.image_urls[lookIndex], {
                    width: 800,
                })}&expand=1x${expandHorizontalState}` + (lookRatio ? `&cratio=${lookRatio}` : '')
        }
        const customHandleOnLoad = handleOnLoad.bind(this, src)

        const img = new Image()
        handleImgTransition(src, img, customHandleOnLoad)

        // ---- Update local var ----
        localModelId = currentLook.model?.model_id

        return () => {
            if (src !== undefined && src !== null) {
                clearTimeout(startTimeout.current)
                clearTimeout(transitionTimeout.current)
                img.removeEventListener('load', customHandleOnLoad)
                img.removeEventListener('error', handleImgError)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLook])

    return (
        <>
            {expandSrc && (
                <div className='layout--swipe-look-container'>
                    <img id='lookImageContainer' src={expandSrc} draggable={false} />
                </div>
            )}
            {currentSrc && (
                <div className='layout--swipe-look-container'>
                    <img id='lookImageContainer' src={currentSrc} draggable={false} />
                </div>
            )}
            {oldSrc && (
                <AntdImage
                    preview={false}
                    rootClassName='layout--swipe-look-container'
                    src={oldSrc}
                    style={Object.assign(
                        {
                            opacity: oldOpacity,
                        },
                        currentLoaded
                            ? {
                                  transition: `opacity ${transitionTime / 1000}s ease-out 0s`,
                              }
                            : {}
                    )}
                    draggable={false}
                />
            )}
            {isLoading && !noLoader && (
                <>
                    <div className='imagesmooth--loader-bg' />
                    <div className='imagesmooth--loader'>
                        <Loader />
                    </div>
                </>
            )}
        </>
    )
}

export default LookContainer
