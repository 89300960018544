import React, { useEffect } from 'react'
import useCustomTranslation from 'src/utils/translation'
import { ReactFitty } from 'react-fitty'
import { Select } from 'antd'

const { Option } = Select

interface SizeSelectProps {
    placeholder: string
    options: Models.SizeSelectOption[]
    translation?: boolean
    name: string
    value?: string
    onChange(newValue: string | null, name: string): void
    defaultOpen?: boolean
    customStyle?: any
}

const SizeSelect = React.forwardRef<HTMLDivElement, SizeSelectProps>((props, ref) => {
    const { t } = useCustomTranslation()
    const {
        options,
        translation = true,
        placeholder,
        name,
        value,
        defaultOpen = false,
        customStyle = {},
        onChange,
    } = props

    useEffect(() => {
        if (value) {
            const filteredOptions = options.filter((one) => one.value === value)
            if (filteredOptions.length !== 1) {
                onChange(null, name)
            }
        }
        // eslint-disable-next-line
    }, [options, value])

    const getPopupContainer = (trigger) => {
        const closest = trigger.closest('.ant-layout-content')
        return closest || document.body
    }

    const filteredOptions = options.filter((one) => one.value === value)

    const inStockOrPreorder = (option) => {
        if (typeof option.in_stock !== 'undefined') {
            return !!option.in_stock || option.preorder === true
        }
        return true
    }

    return (
        <>
            <Select
                ref={ref}
                size='large'
                placeholder={placeholder ?? ''}
                className='select select--container'
                value={filteredOptions.length === 1 ? value : undefined}
                onClick={(e) => e.stopPropagation()}
                onChange={(newValue) => onChange(newValue, name)}
                defaultOpen={defaultOpen}
                autoFocus={defaultOpen}
                getPopupContainer={getPopupContainer}
                style={customStyle}
                showAction={['focus', 'click']}
            >
                {options.map((option, optionKey) => (
                    <Option
                        className='select--option'
                        key={optionKey}
                        value={option.value}
                        disabled={!inStockOrPreorder(option) || option.disabled}
                    >
                        <div className='select--option-container'>
                            {option.imageUrl && (
                                <img src={option.imageUrl} className='select--option-image' />
                            )}

                            {option.svgUrl && (
                                <svg className='select--option-svg'>
                                    <use href={option.svgUrl} />
                                </svg>
                            )}

                            <div className='select--option-text-container'>
                                <div className='select--option-label'>
                                    {translation ? t(option.label) : option.label}
                                </div>

                                {option.extra && (
                                    <div className='select--option-extra'>
                                        <ReactFitty minSize={9} maxSize={12} wrapText={true}>
                                            {Array.isArray(option.extra)
                                                ? t.apply(this, option.extra)
                                                : option.extra}
                                        </ReactFitty>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Option>
                ))}
            </Select>
        </>
    )
})

SizeSelect.displayName = 'SizeSelect'

export default SizeSelect
