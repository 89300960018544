import React from 'react'

export const customSpinner = (
    <div className='lululemon-custom-spinner' style={{ opacity: 0 }}>
        <div className='container'>
            <svg
                className='logo'
                width='46'
                height='46'
                viewBox='0 0 150 150'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <circle cx='75' cy='75' r='75' fill='black' />
                <path
                    d='M118.064 111.526C113.074 111.491 108.374 109.16 105.306 105.196C101.959 100.639 100.949 94.7558 102.582 89.3311C104.196 83.4448 107.736 78.7823 110.564 73.2125C119.761 57.3893 119.384 45.5535 119.384 45.5535C119.384 22.0085 97.0098 18 76.5 18C55.9902 18 33.6159 22.0085 33.6159 45.6168C33.6159 45.6168 33.2388 57.4526 42.4358 73.2758C45.264 78.7401 48.7207 83.5081 50.4176 89.3944C52.0507 94.8191 51.0409 100.702 47.6941 105.26C44.9146 108.843 40.774 111.094 36.2737 111.466C31.7735 111.839 27.3235 110.3 24 107.222C28.3873 118.388 40.0488 124.825 51.7558 122.542C63.4629 120.26 71.8962 109.905 71.8282 97.8967C71.5231 93.1165 70.3516 88.433 68.3715 84.0778C67.1844 80.8428 65.9972 77.6289 64.8101 74.4361C61.8204 66.8802 59.5886 59.0421 58.148 51.0389C57.1634 44.7096 57.2682 36.9457 62.0656 32.051C65.7318 28.3378 71.4092 27.5994 76.5 27.4728C81.5908 27.3462 87.2682 28.3378 90.9344 32.0721C95.7318 36.9457 95.8366 44.7307 94.852 51.06C93.41 59.0561 91.1783 66.887 88.1899 74.4361C87.0168 77.643 85.8296 80.8569 84.6285 84.0778C82.6484 88.433 81.4769 93.1165 81.1718 97.8967C81.1038 109.905 89.5371 120.26 101.244 122.542C112.951 124.825 124.613 118.388 129 107.222C126.019 109.985 122.116 111.521 118.064 111.526V111.526Z'
                    fill='white'
                />
            </svg>

            <svg
                className='arc-circle'
                width='14.671145mm'
                height='14.366875mm'
                viewBox='0 0 14.671145 14.366875'
                version='1.1'
                id='svg5'
                xmlns='http://www.w3.org/2000/svg'
            >
                <defs id='defs2' />
                <g id='layer1' transform='translate(-2.0861626e-7,-3.1679868e-5)'>
                    <path
                        style={{
                            fill: 'none',
                            fillOpacity: 1,
                            stroke: '#000000',
                            strokeWidth: 0.608542,
                            strokeLinecap: 'round',
                            strokeLinejoin: 'miter',
                            strokeDasharray: 'none',
                            strokeOpacity: 1,
                            paintOrder: 'normal',
                        }}
                        id='circle2475'
                        d='M 7.4877086,0.30430269 A 6.8791661,6.8791666 0 0 1 14.366875,7.1834693'
                    />
                    <circle
                        style={{
                            fill: 'none',
                            fillOpacity: 1,
                            stroke: '#000000',
                            strokeWidth: 0.608542,
                            strokeLinecap: 'round',
                            strokeLinejoin: 'miter',
                            strokeDasharray: 'none',
                            strokeOpacity: 0,
                            paintOrder: 'normal',
                        }}
                        id='path3283'
                        cx='7.1834378'
                        cy='7.1834693'
                        r='6.8791666'
                    />
                </g>
            </svg>
        </div>
    </div>
)
