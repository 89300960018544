import { Button, Card, Col, Image, Row, Tooltip, Typography } from 'antd'
import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'src/store'
import { HandleLookRequest } from 'src/store/actions/look'
import { toggleModelModal } from 'src/store/slices/layoutSlice'
import { formattedHeight } from 'src/utils/height'
import { resizeImage } from 'src/utils/image'
import { saveUserModel } from 'src/utils/localStorageHelpers'
import { trackEvent } from 'src/utils/tracking'
import useCustomTranslation from 'src/utils/translation'
import Loader from '../Loader'

const { Title, Paragraph } = Typography

interface CardModelProps {
    model: Models.Model
    horizontalMode?: boolean
    modelHeightOffset?: number
}

let checkForDragCardCarousel

export default function CardModel(props: CardModelProps) {
    const { model, horizontalMode, modelHeightOffset } = props
    const { t } = useCustomTranslation()
    const dispatch = useDispatch()

    const lookRequest = useAppSelector((state) => state.look.request)
    const company = useAppSelector((state) => state.profile.company)

    const [truncated, setTruncated] = useState<boolean>(false)

    const description = useMemo(() => {
        // ---- Clone the model object ----
        const translationModelObject: Models.Model = JSON.parse(JSON.stringify(model))

        // ---- Edit the keys here as we can't do logic in the translation files ----
        translationModelObject.model_name = model.model_display || model.model_name
        translationModelObject.model_height = formattedHeight(
            model.model_height,
            company?.height_format
        )

        // ---- Use either the description or the translation with the new model object as param ----
        return model.model_description || t('model.description', { model: translationModelObject })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [model])

    const handleSelectClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        trackEvent('Model Selected', model, 'Model Choice')
        dispatch(
            HandleLookRequest({ lookRequest: { ...lookRequest, model: model }, focus: 'MODEL' })
        )

        // ---- Save model in local storage ----
        saveUserModel(model)

        return dispatch(toggleModelModal())
    }

    const handleCardClick = (e: React.MouseEvent<HTMLElement>) => {
        const mouseUp = e.clientX

        // it's swiping so we ignore
        if (mouseUp > checkForDragCardCarousel + 6 || mouseUp < checkForDragCardCarousel - 6) {
            return
        }

        trackEvent('Model card Clicked', [model], 'Model Choice')
        handleSelectClick(e)
    }

    const mouseDownCoords = (e: React.MouseEvent<HTMLElement>) => {
        checkForDragCardCarousel = e.clientX
    }

    return (
        <Card
            className={`override_card_container card-carousel--container ${
                horizontalMode ? 'card-carousel--container--horizontal' : ''
            }`}
            cover={
                <Image
                    src={
                        resizeImage(model.image_url, { width: 800 }) +
                        (company?.look_image_ratio ? `&cratio=${company?.look_image_ratio}` : '') +
                        `&expand=1x2`
                    }
                    onMouseDown={mouseDownCoords}
                    onMouseUp={handleCardClick}
                    preview={false}
                    placeholder={<Loader />}
                    draggable={false}
                />
            }
            //! ---- Need to be synchronized with the carouselModel center padding calculus ----
            style={{
                maxWidth: horizontalMode
                    ? '100%'
                    : `calc((100vh - 32px - 20px - 31px${
                          modelHeightOffset ? ` - ${modelHeightOffset}px` : ''
                      }) * ${company.look_image_ratio})`,
            }}
            hoverable
        >
            <Col>
                <Title
                    ellipsis={{
                        rows: 1,
                    }}
                    className='card-carousel--title'
                >
                    {model.model_display || model.model_name}
                </Title>
                <Tooltip title={truncated && description} trigger={['hover', 'click']}>
                    <Paragraph
                        ellipsis={{
                            rows: horizontalMode ? 9 : 3,
                            onEllipsis: setTruncated,
                        }}
                        className='card-carousel--text'
                    >
                        {description}
                    </Paragraph>
                </Tooltip>
            </Col>

            <Row align='middle' justify='center'>
                <Button
                    className='button--primary card-carousel--button'
                    type='primary'
                    onClick={handleSelectClick}
                >
                    {t('model.select')}
                </Button>
            </Row>
        </Card>
    )
}
