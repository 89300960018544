import React from 'react'

import Icon, {
    DownOutlined,
    InfoCircleOutlined,
    LeftOutlined,
    MinusOutlined,
    PlusOutlined,
    RightOutlined,
    ZoomInOutlined,
} from '@ant-design/icons'
import { HeartOutlined, HeartFilled } from '@ant-design/icons'
import { ReactComponent as BookmarkOutlined } from '../static/icons/bookmark.svg'
import { ReactComponent as BookmarkFilled } from '../static/icons/bookmark-solid.svg'
import { ReactComponent as FilterSvg } from '../static/icons/sliders-solid.svg'
import { ReactComponent as SyncSvg } from '../static/icons/sync.svg'
import { ReactComponent as DrawerUp } from '../static/icons/drawer.svg'
import { ReactComponent as DrawerRight } from '../static/icons/drawer_horizontal.svg'
import { ReactComponent as ColorWheel } from '../static/icons/color.svg'
import { ReactComponent as CustomPlus } from '../static/icons/plus.svg'
import { ReactComponent as CustomMinus } from '../static/icons/minus.svg'
import { ReactComponent as ExpandIcon } from '../static/icons/expand.svg'
import { ReactComponent as ContractIcon } from '../static/icons/contract.svg'
import { ReactComponent as EileenHeart } from '../static/icons/eileen_heart.svg'
import { ReactComponent as EileenHeartFilled } from '../static/icons/eileen_heart_filled.svg'
import { ReactComponent as EileenInfo } from '../static/icons/eileen_info.svg'
import { ReactComponent as EileenZoom } from '../static/icons/eileen_zoom_plus.svg'
import { ReactComponent as EileenLeft } from '../static/icons/eileen_left.svg'
import { ReactComponent as EileenRight } from '../static/icons/eileen_right.svg'

import store from 'src/store'

export const getFavoriteIcon = (mode = 'outlined', color?: string) => {
    const internal = store.getState().profile?.company?.internal

    switch (mode) {
        case 'outlined':
            if (internal && internal.match(/^laredoute/)) {
                return <Icon component={BookmarkOutlined} />
            }

            if (internal && internal.match(/^eileenfisher/)) {
                return <Icon component={EileenHeart} />
            }
            return <HeartOutlined style={{ color: color }} />
        case 'filled':
            if (internal && internal.match(/^laredoute/)) {
                return <Icon component={BookmarkFilled} />
            }

            if (internal && internal.match(/^eileenfisher/)) {
                return <Icon component={EileenHeartFilled} />
            }
            return <HeartFilled style={{ color: color }} />
    }
}

export const getFilterIcon = () => {
    return <Icon component={FilterSvg} style={{ fontSize: 24 }} />
}

export const getSyncIcon = () => {
    return <Icon component={SyncSvg} />
}

export const getDrawerUpIcon = () => {
    return <Icon component={DrawerUp} />
}

export const getDrawerDownIcon = () => {
    return <Icon component={DrawerUp} rotate={180} />
}

export const getDrawerRightIcon = () => {
    return <Icon component={DrawerRight} />
}

export const getDrawerLeftIcon = () => {
    return <Icon component={DrawerRight} rotate={180} />
}

export const getColorIcon = () => {
    return <Icon component={ColorWheel} style={{ fontSize: 24 }} />
}

export const getCustomPlusIcon = () => {
    return <Icon component={CustomPlus} style={{ fontSize: 24 }} className='plusminus-icon' />
}

export const getCustomMinusIcon = (customStyle?: React.CSSProperties) => {
    return (
        <Icon
            component={CustomMinus}
            style={{ fontSize: 24, ...customStyle }}
            className='plusminus-icon'
        />
    )
}

export const getInfoIcon = () => {
    const internal = store.getState().profile?.company?.internal

    if (internal && internal.match(/^eileenfisher/)) {
        return <Icon component={EileenInfo} />
    }
    return <InfoCircleOutlined />
}

export const getZoomIcon = (customStyle?: React.CSSProperties) => {
    const internal = store.getState().profile?.company?.internal

    if (internal && internal.match(/^eileenfisher/)) {
        return <Icon component={EileenZoom} style={customStyle} />
    }
    return <ZoomInOutlined />
}

export const getLeftIcon = () => {
    const internal = store.getState().profile?.company?.internal

    if (internal && internal.match(/^eileenfisher/)) {
        return <Icon component={EileenLeft} />
    }
    return <LeftOutlined />
}

export const getRightIcon = () => {
    const internal = store.getState().profile?.company?.internal

    if (internal && internal.match(/^eileenfisher/)) {
        return <Icon component={EileenRight} />
    }
    return <RightOutlined />
}

export const getCollapseCheckboxIcon = (isActive: boolean) => {
    const internal = store.getState().profile?.company?.internal

    if (internal && (internal.match(/^eileenfisher/) || internal.match(/^afibel/))) {
        return isActive ? (
            <MinusOutlined style={{ color: 'black' }} />
        ) : (
            <PlusOutlined style={{ color: 'black' }} />
        )
    }

    return <DownOutlined />
}

export const getExpandIcon = () => {
    return <Icon component={ExpandIcon} />
}

export const getContractIcon = () => {
    return <Icon component={ContractIcon} />
}
